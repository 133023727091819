import { s__ } from '~/locale';

export const i18n = {
  GITLAB_DUO: s__('AI|GitLab Duo'),
  GENIE_TOOLTIP: s__('AI|What does the selected code mean?'),
  GENIE_NO_CONTAINER_ERROR: s__("AI|The container element wasn't found, stopping AI Genie."),
  GENIE_CHAT_LEGAL_GENERATED_BY_AI: s__('AI|Responses generated by AI'),
  REQUEST_ERROR: s__('AI|Something went wrong. Please try again later'),
  GENIE_CHAT_FEEDBACK_LINK: s__('AI|Give feedback to improve this answer.'),
  GENIE_CHAT_FEEDBACK_THANKS: s__('AI|Thank you for your feedback.'),
};
export const AI_GENIE_DEBOUNCE = 300;
export const GENIE_CHAT_MODEL_ROLES = {
  user: 'user',
  system: 'system',
  assistant: 'assistant',
};

export const CHAT_MESSAGE_TYPES = {
  tool: 'tool',
};

export const EXPLAIN_CODE_TRACKING_EVENT_NAME = 'explain_code_blob_viewer';
export const TANUKI_BOT_TRACKING_EVENT_NAME = 'ask_gitlab_chat';
export const GENIE_CHAT_RESET_MESSAGE = '/reset';
export const GENIE_CHAT_CLEAR_MESSAGE = '/clear';
export const GENIE_CHAT_EXPLAIN_MESSAGE = '/explain';
